<template>
  <div class="consumables">
    <BaseDialog
      :title="ruleForm.id ? '修改床位' : '新增床位'"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="900px"
      top="10%"
    >
      <div class="consumables-search">
        <el-form
          :model="ruleForm"
          ref="ruleFormRef"
          label-width="100px"
          label-position="right"
          :rules="Rules.ADDBED"
          :inline="true"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="床位号：" prop="bed_no">
            <el-input
              v-model="ruleForm.bed_no"
              placeholder="请输入床位号"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属院区：" prop="hospital_area_id">
            <el-select
              @change="hospitalChe"
              v-model="ruleForm.hospital_area_id"
              placeholder="请选择"
            >
              <el-option
                v-for="item in hospitalList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属分组：" prop="bed_area_id">
            <el-select v-model="ruleForm.bed_area_id" placeholder="请选择">
              <el-option
                v-for="item in groupingList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序号：">
            <el-input
              v-model="ruleForm.sort"
              placeholder="请输入排序号"
            ></el-input>
          </el-form-item>
          <el-form-item class="addr" label="备注：">
            <el-input
              v-model="ruleForm.remark"
              type="textarea"
              :rows="2"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="savePatient"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  onMounted,
  defineExpose,
  defineEmits,
  nextTick,
} from 'vue'
import service from '@/utils/request'
import BaseDialog from '@/components/Dialog/index.vue'
import { ElMessage } from 'element-plus'
import Rule from '@/utils/rule'

const userInfo = localStorage.getItem('ms_userData')
const ruleForm = ref({})
ruleForm.value.hospital_area_id = userInfo.hospital
const visible = ref(false)
const hospitalList = ref([])
const groupingList = ref([])
const ruleFormRef = ref(null)
const Rules = reactive(Rule)

onMounted(() => {
  getHospitalList(0)
})

const initData = (item) => {
  visible.value = true
  if (item) {
    ruleForm.value = item
    getHospitalList(ruleForm.value.hospital_area_id)
  } else {
    ruleForm.value = {
      hospital_area_id: userInfo.hospital,
    }
    if (hospitalList.value.length > 0 && !ruleForm.value.hospital_area_id) {
      ruleForm.value.hospital_area_id = hospitalList.value[0].id
    }
    if (groupingList.value.length > 0) {
      ruleForm.value.bed_area_id = groupingList.value[0].id
    }
  }
  nextTick(() => {
    ruleFormRef.value.clearValidate()
  })
}

const getHospitalList = async (id) => {
  let res = await service.post('/api/hospital/list', {
    parent_id: id,
  })
  if (res.code === 0) {
    if (id == 0) {
      hospitalList.value = res.data
      if (!ruleForm.value.hospital_area_id) {
        ruleForm.value.hospital_area_id = res.data[0].id
        getHospitalList(res.data[0].id)
      }
    } else {
      groupingList.value = res.data
      if (res.data.length > 0) {
        ruleForm.value.bed_area_id = res.data[0].id
      }
    }
  }
}
const hospitalChe = async (val) => {
  getHospitalList(val)
}

// 保存
const saveLoad = ref(false)
const em = defineEmits(['refreshDataList'])
const savePatient = () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      let url = ''
      if (ruleForm.value.id) {
        url = '/api/bed/edit'
      } else {
        url = '/api/bed/create'
      }
      saveLoad.value = true
      let res = await service.post(url, ruleForm.value)
      saveLoad.value = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        visible.value = false
        em('refreshDataList')
      }
    }
  })
}

defineExpose({
  initData,
})
</script>

<style scoped lang="scss">
.consumables-search {
  margin-top: 16px;
  .el-form-item {
    width: 47%;
    margin-right: 6%;
    &:nth-child(2n) {
      margin-right: 0;
    }
    &.addr {
      width: 100%;
      margin-right: 0;
    }
  }
}
</style>
